@tailwind base;
@tailwind components;
@tailwind utilities;

.infinite-scroll-component {
  overflow: none !important;
}

/* Tổng thể container */
.upload-container {
  font-family: Arial, sans-serif;
  margin: 0px;
}







.sanpham
{
  padding-top: 30px;
}
.sanpham1
{
  padding-top: 30px;
 
  font-size: 22px;
  color: black;
  
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.upload-container1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  
  
  
  
  

 
}
.upload-container1 p {
  font-size: 24px;
  color: #FED700;
  font-weight: 700;
  

 
}
.upload-container h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 1rem;
}

input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}

.upload-container input[type="file"] {
  float: right;
  background-color: white;
  position: relative;
  color: black;
  height: 100%;
  border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
  cursor: pointer;
  margin-right: 0;
}

.upload-container input[type="file"]::file-selector-button  {
  border-radius: 4px;
  padding: 4 15px;
  height: 30px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  width: 120px;
  color: transparent;
  margin-right: 15px;
 
  
}
.upload-container input[type="file"]::before  {
  position: absolute;
  pointer-events: none;
  top: 13px;
  left: 16px;
  height: 20px;
  width: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
  
}
.upload-container input[type="file"]::after  {
  position: absolute;
  pointer-events: none;
  top: 13px;
  left: 40px;
  color: #0964b0;
  content: "Upload File";
  
}


.upload-container label {
  font-size: 16px;
  background-color: #1e90ff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-container label:hover {
  background-color: #006bb3;
}

.upload-container .upload-button {
  margin-top: 20px;
  background-color: #28a745;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-container .upload-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.upload-container .upload-button:hover {
  background-color: #218838;
}

.upload-container .status-message {
  margin-top: 1rem;
  color: #f39c12;
  font-size: 16px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 93%;
  height: 80px;
  color: #FED700 !important;
  display: flex;
  justify-content: center;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #FED700;
  margin: -4px 0 0 -4px;

}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}